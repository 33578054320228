import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sessionToken: null,
    upvotedIssues: [],
    serverStatus: [],
  },
  getters: {
    sessionToken: state => {
      return state.sessionToken
    },
    upvotedIssues: state => {
      return state.upvotedIssues;
    },
    serverStatus: state => {
      return state.serverStatus;
    },
  },
  mutations: {
    setSessionToken (state, newSessionToken) {
      state.sessionToken = newSessionToken
    },
    storeUpvotedIssueID(state, issueId) {
      state.upvotedIssues.push(issueId);
    },
    updateServerStatus(state, serverDetails) {
      state.serverStatus = serverDetails;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
