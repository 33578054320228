import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CarbonComponentsVue from "@carbon/vue/src/index";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMasonry from "vue-masonry-css";

Vue.use(CarbonComponentsVue);
Vue.use(VueMasonry);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
