import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store';

Vue.use(VueRouter);

async function adminAuth(to, from, next) {
  if (store.getters.sessionToken) {
    next();
    return;
  }

  next({ name: "Login" });
}

const routes = [
  {
    path: "/",
    name: "Bugs",
    component: () => import(/* webpackChunkName: "bugs" */ "../views/Bugs.vue")
  },
  {
    path: "/report-bug",
    name: "Report Bug",
    component: () =>
      import(/* webpackChunkName: "reportbug" */ "../views/ReportBug.vue")
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: () =>
      import(/* webpackChunkName: "AdminHome" */ "../views/admin/Home.vue"),
    beforeEnter: adminAuth
  },
  {
    path: '/admin/issues/new',
    name: 'New Issue',
    component: () => import(/* webpackChunkName: "issueform" */ '../views/admin/IssueForm.vue'),
    beforeEnter: adminAuth
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/admin/Login.vue')
  },
  {
    path: "/status",
    name: "Status",
    component: () =>
      import(/* webpackChunkName: "status" */ "../views/Status.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
